import service from "@/axios";

const api = {
  login: (data: any) => {
    return service({
      url: "/login",
      method: "post",
      data,
    });
  },
  orderList: (params: any) => {
    return service({
      url: "/order/list",
      method: "get",
      params: params,
    });
  },
  setConfig: (data: any) => {
    return service({
      url: "/config/set",
      method: "post",
      data,
    });
  },
  getConfig: (params: any) => {
    return service({
      url: "/config/list",
      method: "get",
      params: params,
    });
  },
  getTypeList: () => {
    return service({
      url: "/config/typeList",
      method: "get",
    });
  },
};

export default api;

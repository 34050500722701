import axios from "axios";
import type {
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import { ElMessage, ElLoading } from "element-plus";
import type { LoadingInstance } from "element-plus/es/components/loading/src/loading";
let loadingInstance: LoadingInstance;

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, //请求后端数据的基本地址，自定义
  timeout: 120000, //请求超时设置，单位ms
});

// 请求拦截
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    loadingInstance = ElLoading.service({
      text: "加载中，请等待",
    });
    if (sessionStorage.getItem("Authorization")) {
      config.headers.Authorization = sessionStorage.getItem("Authorization");
    }
    return config;
  },
  (error: unknown) => {
    loadingInstance.close();
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (response: AxiosResponse) => {
    loadingInstance.close();
    if (response.config.url == "/login" && response.headers["authorization"]) {
      sessionStorage.setItem(
        "Authorization",
        response.headers["authorization"]
      );
    }
    if (response.config.method == "post" && response.status == 200) {
      ElMessage.success({
        message: response.data.msg,
      });
    }

    return response;
  },
  (error: AxiosError) => {
    loadingInstance.close();
    if (error.response) {
      switch (error.response.status) {
        case 500:
          ElMessage({
            message: "服务器错误，请稍后重试",
            type: "error",
            duration: 5 * 1000,
          });
          break;
        default:
          return Promise.reject(error);
      }
    }
  }
);

export default service;
